window.onscroll = function() {
  headerFix();
};

let btnOpenModal = document.querySelectorAll(".js-open-modal");
let btnCloseModal = document.querySelectorAll(".js-close-modal");

btnOpenModal.forEach(function(item) {
  item.addEventListener("click", openModal.bind(item), false);
});

btnCloseModal.forEach(function(item) {
  item.addEventListener("click", closeModal.bind(item), false);
});

let navButton = document.querySelectorAll(".js-scrollto");

navButton.forEach(function(item) {
  item.addEventListener("click", scrollTo.bind(item), false);
});

function headerFix() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.querySelector(".header").className = "header fixed";
  } else {
    document.querySelector(".header").className = "header";
  }
}

function openModal(e) {
  e.preventDefault();
  document
    .getElementById(this.getAttribute("data-modal"))
    .classList.add("show");
}

function closeModal(e) {
  e.preventDefault();
  document
    .getElementById(this.getAttribute("data-modal"))
    .classList.remove("show");
}

function scrollTo(e) {
  e.preventDefault();
  window.scroll({
    behavior: "smooth",
    left: 0,
    top:
      document.getElementById(this.getAttribute("href").replace("#", ""))
        .offsetTop - document.querySelector(".header").offsetHeight
  });
}
